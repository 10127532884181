import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Container, Elevation } from './Elevation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elevationstyles"
    }}>{`ElevationStyles`}</h1>
    <p>{`ElevationStyle creates a shadow by giving the UI a height. You can set it in five steps.`}</p>
    <h2 {...{
      "id": "elevation"
    }}>{`Elevation`}</h2>
    <Container mdxType="Container">
  <Elevation elevation={1} mdxType="Elevation">elevation1</Elevation>
  <Elevation elevation={2} mdxType="Elevation">elevation2</Elevation>
  <Elevation elevation={3} mdxType="Elevation">elevation3</Elevation>
  <Elevation elevation={4} mdxType="Elevation">elevation4</Elevation>
  <Elevation elevation={5} mdxType="Elevation">elevation5</Elevation>
  <Elevation elevation={6} mdxType="Elevation">elevation5</Elevation>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      